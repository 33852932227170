import React, { createContext, useReducer } from 'react';
import {
	reducer,
	GET_JOBORDERS,
	GET_PR,
	ADD_PR,
	GET_MASTERLIST,
	EDIT_PR,
	DELETE_PR,
} from './PurchaseRequestReducer';
import axios from 'axios';
import { displayErrors, displayNotification } from '../../config/display';
import { headers } from '../../config/token';
import Axios from 'axios';
import moment from 'moment';


export const PrContext = createContext({});

const PurchaseRequestContext = ({ children }) => {
	const API = process.env.REACT_APP_API_URL || 'http://api.exelpack.site/api/';
	const initialValue = {
		joList: [],
		joListLength: 0,
		masterlistOpt: [],
		prList: [],
		prListLength: 0,
	};
	const [value, dispatch] = useReducer(reducer, initialValue);

	// FUNCTIONS
	const getFilterParams = (filter) => {
		let params = '';
		Object.keys(filter).forEach((key) => {
			params += filter[key] ? `&${key}=${filter[key]}` : '';
		});
		return params;
	};

	const getJobOrders = (setLoading, filter, page = 1, shouldLoadAll = ``) => {
		const filterParams = getFilterParams(filter);

		setLoading(true);
		axios
			.get(API + `prms/jolist?page=${page}${filterParams}${shouldLoadAll}`, headers())
			.then(res => {
				const { joList, joListLength } = res.data;

				dispatch({
					type: GET_JOBORDERS,
					payload: {
						joList,
						joListLength,
						page,
					},
				});
				setLoading(false);
			})
			.catch(err => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const getPrItemDetails = (jo_id, code) => {
		return axios.get(API + `prms/pr/${jo_id}?code=${code}`, headers());
	};

	const addPr = (data, closeModal, setLoading) => {
		const isValidated = validateForm(data);

		if (!isValidated) return false;

		setLoading(true);
		axios
			.post(API + `prms/pr`, data, headers())
			.then(res => {
				const { message } = res.data;

				dispatch({
					type: ADD_PR,
					payload: {
						id: data.jo_id,
					},
				});
				displayNotification('success', message);
				setLoading(false);
				closeModal();
			})
			.catch(err => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const editPr = (data, closeModal, setLoading) => {
		if (!data.forwardPr) {
			const isValidated = validateForm(data);

			if (!isValidated) return false;
		}
		setLoading(true);

		axios
			.put(API + `prms/pr/${data.id}`, data, headers())
			.then(res => {
				const { message, newItem } = res.data;

				dispatch({
					type: EDIT_PR,
					payload: {
						newItem,
					},
				});
				displayNotification('success', message);
				setLoading(false);
				closeModal();
			})
			.catch(err => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const deletePr = (id, remarks, setLoading, closeModal) => {
		if (remarks.trim() === '' || remarks === null) {
			displayNotification('error', 'Please input remarks');
			return null;
		}

		setLoading(true);

		axios
			.delete(API + `prms/pr/${id}?remarks=${remarks}`, headers())
			.then(res => {
				const { message } = res.data;

				dispatch({
					type: DELETE_PR,
					payload: {
						id,
					},
				});
				displayNotification('success', message);
				closeModal();
				setLoading(false);
			})
			.catch(err => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const getMasterlist = setLoading => {
		setLoading(true);

		axios
			.get(API + 'inventory/option/masterlist', headers())
			.then(res => {
				const { masterlistOpt } = res.data;
				dispatch({
					type: GET_MASTERLIST,
					payload: {
						masterlistOpt,
					},
				});

				setLoading(false);
			})
			.catch(err => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const getPrList = (setLoading, filter, paginate) => {
		const { showRecord, sort, search, searchItem } = filter;
		let filter_params = `&showRecord=${showRecord}&sort=${sort}`;

		if (search !== undefined && search && search.trim() !== '') {
			filter_params += `&search=${search}`;
		}

		if (searchItem !== undefined && searchItem && searchItem.trim() !== '') {
			filter_params += `&searchItem=${searchItem}`;
		}
		setLoading(true);
		const { page, pageSize } = paginate;
		axios
			.get(API + `prms/pr?page=${page}&pageSize=${pageSize}${filter_params}`, headers())
			.then(res => {
				const { prList, prListLength } = res.data;
				dispatch({
					type: GET_PR,
					payload: {
						prList,
						prListLength,
					},
				});
				setLoading(false);
			})
			.catch(err => {
				displayErrors(err);
				setLoading(false);
			});
	};

	const getItemDeliveryAndIssuance = id => Axios.get(`${API}prms/pr/item/${id}`, headers())

	const validateForm = data => {
		//validate
		const validate = [];

		if (data.remarks.length > 200) validate.push('Remarks may not be greater than 200 characters');

		if (data.items.length > 0) {
			data.items.forEach(item => {
				if (item.quantity < 1) {
					validate.push(item.code + ` quantity must be greater than 0`);
				}
				if (item.unit === '' || item.unit === null) {
					validate.push(`Please select unit for ${item.code}`);
				}
			});
		} else validate.push('Purchase requisition must have atleast one item!');

		if (validate.length > 0) {
			validate.forEach(message => displayNotification('warning', message));
			return false;
		}

		return true;
		//end validate
	};

	// const exportCsv = () => {

		
	// 	const dateNow = moment().format('Y_M_D');
	// 	axios({
	// 	  url: API + `prms/prlistitems/exports`,
	// 	  method: 'get',
	// 	  responseType: 'blob',
	// 	  ...headers()
	// 	})
	// 	  .then(res => {
	// 		displayNotification('success', 'File successfully generated');
	// 		const url = window.URL.createObjectURL(new Blob([res.data]));
	// 		const link = document.createElement('a');
	// 		link.href = url;
	// 		link.setAttribute('target', '_blank');
	// 		link.setAttribute('download', `Product&Materials_masterlist_as_of_${dateNow}.xlsx`);
	// 		document.body.appendChild(link);
	// 		link.click();
	// 		link.parentElement.removeChild(link);
			
	
	// 	  })
	// 	  .catch(err => {
	// 		displayErrors(err);
		
	
	// 	  })
	
	//   }prms/jolist/export

	  const exportCsv = (setLoading) => {

		setLoading(true);
		const dateNow = moment().format('Y_M_D');
		axios({
		  url: API + `prms/prlistitems/export`,
		  method: 'get',
		  responseType: 'blob',
		  ...headers()
		})
		  .then(res => {
			displayNotification('success', 'File successfully generated');
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('target', '_blank');
			link.setAttribute('download', `Prlist_export${dateNow}.xlsx`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
			setLoading(false);
	
		  })
		  .catch(err => {
			displayErrors(err);
			setLoading(false);
	
		  })
	
	  }


	  const exportCsvs = (setLoading) => {

		setLoading(true);
		const dateNow = moment().format('Y_M_D');
		axios({
		  url: API + `prms/jolist/export`,
		  method: 'get',
		  responseType: 'blob',
		  ...headers()
		})
		  .then(res => {
			displayNotification('success', 'File successfully generated');
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('target', '_blank');
			link.setAttribute('download', `joborlist_export${dateNow}.xlsx`);
			document.body.appendChild(link);
			link.click();
			link.parentElement.removeChild(link);
			setLoading(false);
	
		  })
		  .catch(err => {
			displayErrors(err);
			setLoading(false);
	
		  })
	
	  }

	return (
		<PrContext.Provider
			value={{
				value,
				getJobOrders,
				getPrItemDetails,
				getMasterlist,
				getPrList,
				addPr,
				editPr,
				deletePr,
				getItemDeliveryAndIssuance,
				exportCsv,
				exportCsvs,
			}}
		>
			{children}
		</PrContext.Provider>
	);
};

export default PurchaseRequestContext;
